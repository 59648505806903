import { doc, setDoc, getDoc, deleteDoc, runTransaction } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { saveAs } from 'file-saver';

import { firestore, storage } from "@/lib/hooks/firebase";

import { toast } from "sonner";

import logger from "@/lib/logger";
import { TimelineMilestoneData } from "./interfaces";

export enum WriteMode {
  Default = 'default',
  Both = 'both'
}

export class StorageManager {
  storageType: string;
  writeMode: WriteMode;

  constructor(storageType = 'firestore', writeMode = WriteMode.Default) {
    this.storageType = storageType;
    this.writeMode = writeMode;

    // logger.debug(`StorageManager initialized with storage type: ${this.storageType}, write mode: ${this.writeMode}`);
  }

  async getNextVersionNumber(collectionName: string, docId: string): Promise<number> {
    const versionCounterRef = doc(firestore, collectionName, docId, 'metadata', 'versionCounter');

    return await runTransaction(firestore, async (transaction) => {
      const versionDoc = await transaction.get(versionCounterRef);
      const currentVersion = versionDoc.exists() ? versionDoc.data().currentVersion : 0;
      const nextVersion = currentVersion + 1;

      logger.debug(`Current version: ${currentVersion}, Next version: ${nextVersion}`);

      transaction.set(versionCounterRef, { currentVersion: nextVersion }, { merge: true });

      return nextVersion;
    });
  }

  async createVersion(collectionName: string, docId: string, data: unknown) {
    const versionNumber = await this.getNextVersionNumber(collectionName, docId);
    const timestamp = new Date().toISOString();
    const versionId = `v${versionNumber}`;
    const versionRef = doc(firestore, collectionName, docId, 'versions', versionId);

    let versionData: Record<string, unknown>;

    if (typeof data === 'object' && data !== null) {
      versionData = {
        ...data as Record<string, unknown>,
        versionNumber,
        timestamp,
      };
    } else {
      versionData = {
        data,
        versionNumber,
        timestamp,
      };
    }

    await setDoc(versionRef, versionData);
    logger.debug(`Version ${versionId} (${timestamp}) created for document ${docId} in collection ${collectionName}`);
  }

  async set(collectionName: string, docId: string, value: unknown, propertyName?: string) {
    logger.debug(`Setting data in ${this.storageType} for collection: ${collectionName}, docId: ${docId}, propertyName: ${propertyName}`);
    logger.debug(`Data`, value);

    try {
      if (this.storageType === 'firestore' || this.writeMode === WriteMode.Both) {
        const docRef = doc(firestore, collectionName, docId);

        await runTransaction(firestore, async (transaction) => {
          const currentTime = Date.now();
          const backupInterval = 30 * 60 * 1000; // 30 minutes in milliseconds
          const lastBackupKey = `lastBackup_${collectionName}_${docId}`;
          const lastBackupTime = localStorage.getItem(lastBackupKey);

          let shouldBackup = false;
          if (collectionName === 'timelines' || collectionName === 'users' || collectionName === 'notes') {
            if (!lastBackupTime || (currentTime - parseInt(lastBackupTime)) >= backupInterval) {
              shouldBackup = true;
            }
          }

          if (shouldBackup) {
            // Only fetch the document snapshot if we need to create a backup
            const docSnap = await transaction.get(docRef);
            if (docSnap.exists()) {
              await this.createVersion(collectionName, docId, docSnap.data());
              localStorage.setItem(lastBackupKey, currentTime.toString());
            }
          }

          const data = propertyName ? { [propertyName]: value, updatedDate: new Date().toISOString() } : value;
          transaction.set(docRef, data, { merge: true });
        });

        logger.debug(`Data set in firestore collection '${collectionName}', doc id '${docId}'${propertyName ? `, property '${propertyName}'` : ''}.`);
      }

      if (this.storageType === 'local' || this.writeMode === WriteMode.Both) {
        const key = `${collectionName}-${docId}${propertyName ? `-${propertyName}` : ''}`;
        localStorage.setItem(key, JSON.stringify(value));
        logger.debug(`Data set for key '${key}' in localStorage.`);
      }

    } catch (error) {
      logger.error('Error setting value:', error);
      toast.error('Error saving data. Please try again.');
      throw error;
    }
  }

  async get(collectionName: string, docId: string, propertyName?: string) {
    logger.debug(`Retrieving data from ${this.storageType} for collection: ${collectionName}, docId: ${docId}`);
    // toast.message('Retrieving data...');

    try {
      if (this.storageType === 'local') {
        const key = `${collectionName}-${docId}${propertyName ? `-${propertyName}` : ''}`;
        const item = localStorage.getItem(key);

        if (item && item !== "undefined") {
          try {
            const parsedData = JSON.parse(item);
            logger.debug(`Data retrieved for key '${key}':`, parsedData);
            return parsedData;
          } catch (e) {
            logger.error(`Error parsing data for key '${key}' from localStorage:`, e);
            return null;
          }
        } else {
          logger.debug(`No data found for key '${key}' in localStorage.`);
          return null;
        }
      } else {
        const docRef = doc(firestore, collectionName, docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = propertyName ? docSnap.data()[propertyName] : docSnap.data();
          logger.debug(`Data retrieved for collection '${collectionName}', doc id '${docId}'${propertyName ? `, property '${propertyName}'` : ''}:`, data);
          // toast.success('Data retrieved.');
          return data;
        } else {
          logger.debug("No such document!");
          // toast.success('Document not found.');
          return null;
        }
      }
    } catch (error) {
      logger.error('Error getting value:', error);
      toast.error('Error retrieving data. Please try again.');

      throw error;
    }
  }

  async delete(collectionName: string, docId: string) {
    logger.debug(`Deleting data in ${this.storageType} for collection: ${collectionName}, docId: ${docId}`);
    // toast.message('Deleting data...');

    try {
      if (this.storageType === 'local' || this.writeMode === WriteMode.Both) {
        const keyPrefix = `${collectionName}-${docId}`;
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith(keyPrefix)) {
            localStorage.removeItem(key);
            logger.debug(`Data deleted for key '${key}' in localStorage.`);
          }
        });
      }

      if (this.storageType === 'firestore' || this.writeMode === WriteMode.Both) {
        const docRef = doc(firestore, collectionName, docId);
        await deleteDoc(docRef);
        logger.debug(`Data deleted in firestore collection '${collectionName}' and doc id '${docId}'.`);
      }

      toast.success('Data deleted.');
    } catch (error) {
      logger.error('Error deleting value:', error);
      toast.error('Error deleting data. Please try again.');

      throw error;
    }
  }

  async handleSnapshotUpload(imageDataUrl: string, planId: string | null, snapshotId: string) {
    logger.debug(`Uploading snapshot to ${this.storageType} for plan: ${planId}, snapshot: ${snapshotId}`);

    if (this.storageType === 'firestore' || this.writeMode === WriteMode.Both) {
      const blob = await (await fetch(imageDataUrl)).blob();
      const file = new File([blob], `${snapshotId}.png`, { type: 'image/png' });

      try {
        return await this.uploadImage(file, planId, snapshotId);
      } catch (error) {
        logger.error("Error uploading snapshot to Firestore:", error);
        toast.error('Error uploading snapshot. Please try again.');
        throw error;
      }
    } else {
      return imageDataUrl;
    }
  }

  async uploadImage(file: File, planId: string | null, snapshotId: string) {
    logger.debug(`Uploading image to storage for plan: ${planId}, snapshot: ${snapshotId}`);

    let storageRef;
    if (planId) {
      // For plan snapshots
      storageRef = ref(storage, `timelines/${planId}/snapshots/${snapshotId}`);
    } else {
      // For priorities snapshots
      storageRef = ref(storage, `priorities/snapshots/${snapshotId}`);
    }
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  }

  async getSnapshotImageUrl(planId: string | null, snapshotId: string): Promise<string> {
    let storageRef;
    if (planId) {
      // For plan snapshots
      storageRef = ref(storage, `timelines/${planId}/snapshots/${snapshotId}`);
    } else {
      // For priorities snapshots
      storageRef = ref(storage, `priorities/snapshots/${snapshotId}`);
    }
    return await getDownloadURL(storageRef);
  }

  async downloadImage(imageUrl: string, snapshotId: string) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      saveAs(blob, `snapshot_${snapshotId}.jpg`);
    } catch (error) {
      logger.error("Error downloading image:", error);
      toast.error("Failed to download image");
    }
  };

  async addMilestoneToPlan(newPlanId, milestone, okr, icon, retro) {
    if (!milestone) {
      logger.error('Milestone is undefined.');
      throw new Error('Milestone is required.');
    }

    logger.info(`Adding milestone and related data to new plan: ${newPlanId}`);

    // Get or create the plan document reference
    const planRef = doc(firestore, 'timelines', newPlanId);
    const planSnapshot = await getDoc(planRef);
    const planData = planSnapshot.exists() ? planSnapshot.data() : {};

    // Add the milestone to the plan
    const updatedEvents = planData.events ? [...planData.events, milestone] : [milestone];

    // Add the OKR related to the milestone
    const updatedOkrs = okr && planData.okrs ? [...planData.okrs, okr] : okr ? [okr] : planData.okrs;

    // Add or update the icon for the milestone
    const updatedIcons = icon ? { ...planData.icons, [milestone.id]: icon } : planData.icons;

    // Add the retrospective related to the milestone
    const updatedRetros = retro && planData.retros ? [...planData.retros, retro] : retro ? [retro] : planData.retros;

    // Check for undefined inputs and log errors
    if (okr === undefined) logger.warn('OKR is undefined.');
    if (icon === undefined) logger.warn('Icon is undefined.');
    if (retro === undefined) logger.warn('Retro is undefined.');

    // Update the plan document with the new data
    await setDoc(planRef, {
      events: updatedEvents,
      okrs: updatedOkrs || [],
      icons: updatedIcons || {},
      retros: updatedRetros || []
    }, { merge: true });

    logger.info(`Milestone and related data added to new plan successfully.`);
  }

  async saveMilestonesAcrossPlans(
    userId: string,
    updatedMilestonesByPlan: { [planId: string]: TimelineMilestoneData[] },
    onSuccess: () => void,
    onError: (error: Error) => void
  ) {
    try {
      const response = await fetch('/api/updateMilestonesCache', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          updatedMilestonesByPlan
        })
      });

      if (response.ok) {
        logger.debug('Milestones and cache updated successfully');
        onSuccess();
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to update milestones and cache: ${errorText}`);
      }
    } catch (error) {
      logger.error('Error updating milestones and cache:', error);
      onError(error);
    }
  }
}